import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas, far, fab);

const IconPicker = ({ selectedIcon, setSelectedIcon }) => {
  const [allFaIcons, setAllFaIcons] = useState([]);
  const [filteredIcons, setFilteredIcons] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fabIcons = Object.keys(library.definitions.fab);
    const farIcons = Object.keys(library.definitions.far);
    const fasIcons = Object.keys(library.definitions.fas);

    const fabIconsData = fabIcons.map((iconName) => {
      return {
        iconName,
        prefix: "fab",
      };
    });

    const farIconsData = farIcons.map((iconName) => {
      return {
        iconName,
        prefix: "far",
      };
    });

    const fasIconsData = fasIcons.map((iconName) => {
      return {
        iconName,
        prefix: "fas",
      };
    });

    const currentFaIcons = [...farIconsData, ...fasIconsData, ...fabIconsData];

    setAllFaIcons(currentFaIcons);
  }, [library]);

  const handleSearchIcon = (event) => {
    const value = event.target.value;

    const currentIcons = allFaIcons.filter((icon) => {
      return icon.iconName.includes(value);
    });

    setFilteredIcons(currentIcons.slice(0, 35));
    setSearch(value);
  };

  const renderIcons = () => {
    let iconsToRender;

    if (search?.length > 0) {
      iconsToRender = [...filteredIcons].splice(0, 35);
    } else {
      iconsToRender = [...allFaIcons].splice(0, 35);
    }

    return iconsToRender?.map((icon, index) => {
      return (
        <div
          key={`${icon.prefix}-${icon.iconName}`}
          className="p-0 cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            width: "50px",
            height: "50px",
          }}
        >
          <button
            type="button"
            className="w-100 h-100 btn btn-light border p-0"
            onClick={() => {
              setSelectedIcon(`${icon.prefix} fa-${icon.iconName}`);
            }}
          >
            <i
              className={`${icon.prefix} fa-${icon.iconName}`}
              style={{ fontSize: "20px" }}
            />
          </button>
        </div>
      );
    });
  };

  return (
    <div className="dropdown d-flex justify-content-between align-items-center mb-3">
      <button
        className="btn dropdown-toggle me-3"
        type="button"
        style={{
          flex: 1,
          backgroundColor: "#EAECEF",
          border: "1x solid",
          borderColor: "#ced4da",
        }}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Seleccionar Icono
      </button>
      <div
        className="card d-inline-flex justify-content-center align-items-center p-0"
        style={{ width: "38px", height: "38px" }}
      >
        <i className={`${selectedIcon}`} style={{ fontSize: "20px" }} />
      </div>
      <div className="dropdown-menu w-100">
        <div className="row w-100 mx-auto">
          <div className="col-12">
            <input
              placeholder="Buscar icono por nombre"
              className="form-control mb-2"
              onChange={handleSearchIcon}
            />
          </div>

          <div className="col-12">
            <div
              className=" w-100 mx-auto"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(50px, 1fr))",
                gridGap: "10px",
                width: "100%",
              }}
            >
              {renderIcons()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IconPicker;
