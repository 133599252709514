import React, { useContext } from "react";
import moment from "moment";
import MapaLugares from "../schedule/MapaLugares";
import SingleClassActions from "./SingleClassActions";
import { UserContext } from "../../context/UserContext";

const SingleClassData = ({ single_class }) => {
  const { user } = useContext(UserContext);

  const getSpots = () => {
    if(single_class.class_type) {
      return single_class.class_type?.spot_map
      .split(",")
      .map((number) => parseInt(number));
    }
  };

  const renderClassTypeName = () => {
    if (single_class.class_type && single_class.class_type !== null) {
      return single_class.class_type.name;
    }
  };

  const renderLocationName = () => {
    if (single_class.location && single_class.location !== null) {
      return single_class.location.name;
    }
  };

  const renderButtons = () => {
    if (["admin", "manager"].includes(user.role)) {
      return <SingleClassActions single_class={single_class} />;
    }
  };

  const renderSpotMap = () => {
    if (
      single_class.class_type !== null &&
      single_class.class_type?.spot_map !== null
    ) {
      return (
        <MapaLugares
          rows={getSpots()}
          taken_spots={single_class?.asistentes?.map(({ spot }) => spot)}
        />
      );
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <h4>Datos de Clase</h4>
        <p>
          {moment(single_class.class_date).format("ddd DD MMM, HH:mm")}
        </p>
        <p>{renderClassTypeName()}</p>
        <p>{renderLocationName()}</p>
        {renderButtons()}
      </div>
      <div className="col col-md-6">{renderSpotMap()}</div>
    </div>
  );
};

export default SingleClassData;
