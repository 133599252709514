import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import useAppConfigValues from "../../hooks/useAppConfigValues";

const defaultStartDate = moment().startOf('day').format('YYYY-MM-DD');
const defaultExpirationDate = moment().add(30, 'days').endOf('day').format('YYYY-MM-DD');

const AddClassesForm = ({ customer_id, addClasses }) => {
  const [amount, setAmount] = useState(0);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [expirationDate, setExpirationDate] = useState(defaultExpirationDate);
  const [classTypeId, setClassTypeId] = useState('');

  const { class_types, getClassTypes } = useContext(ClassTypeContext);
  const { allowRestrictedClassTypes } = useAppConfigValues();

  useEffect(() => {
    getClassTypes();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    addClasses({
      customer_id, 
      amount,
      start_date: startDate,
      expiration_date: expirationDate,
      class_type_id: classTypeId.length > 0 ? classTypeId : null
    });
  };

  const renderRestrictedClassType = () => {
    if(allowRestrictedClassTypes()) {
      return (
        <>
          <label>Tipo de clase restringido</label>
          <select
            value={classTypeId}
            className="form-control mb-3"
            onChange={(e) => setClassTypeId(e.target.value)}
          >
            <option value={''}>Clases no restringidas</option>
            {
              class_types?.map(type => {
                if(type.restricted) {
                  const id = type.class_type_id;
                  const name = type.name;

                  return ( 
                    <option key={id} value={id}>{name}</option>
                  )
                }
              })
            }
          </select>
        </>
      )
    }
  }

  return (
    <div className="container-fluid ">
      <form onSubmit={handleSubmit}>
        <label>Ingresa la cantidad a agregar</label>
        <input
          type="number"
          value={amount}
          className="form-control mb-3"
          onChange={(e) => setAmount(e.target.value)}
        />

        <label>Fecha de Inicio</label>
        <input
          type="date"
          value={startDate}
          className="form-control mb-3"
          onChange={(e) => setStartDate(e.target.value)}
        />

        <label>Fecha de Expiración</label>
        <input
          type="date"
          value={expirationDate}
          className="form-control mb-3"
          onChange={(e) => setExpirationDate(e.target.value)}
        />

        {renderRestrictedClassType()}

        <button type="submit" className="btn btn-success">
          Agregar Clases
        </button>
      </form>
    </div>
  );
};

export default AddClassesForm;
