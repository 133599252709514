import moment from "moment";
import React, { useContext } from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";
import { UserContext } from "../../context/UserContext";

const CustomerRow = ({ customer, extraFields, showCancelReason }) => {
  const { user } = useContext(UserContext);

  const renderName = () => {
    const { name, last_name } = customer;
    return `${name !== null ? name : ""} ${
      last_name !== null ? last_name : ""
    }`;
  };

  const renderExtraFields = () => {
    if (Array.isArray(extraFields)) {
      return extraFields.map((field) => (
        <td key={field}>{customer[field.key]}</td>
      ));
    }
  };

  const renderCancel = () => {
    if (showCancelReason) {
      if (Array.isArray(customer.purchases)) {
        const purchase = customer.purchases.find(
          (purchase) => purchase.cancel_reason !== null
        );
        return <td>{purchase ? purchase.cancel_reason : ""}</td>;
      }
    }
  };

  const renderCustomerTags = () => {
    if(Array.isArray(customer.tags)) {
      return customer.tags.map(tag => {
        return (
          <span key={tag.tag_id} className="badge badge-pill bg-secondary me-1">
            {tag.content}
          </span>
        )
      });
    }
  }

  return (
    <tr className="p-2 border-bottom small align-middle hover-light">
      <td className="td-id">{customer.customer_id}</td>
      <td>
        <Link to={`/myadmin/customer/${customer.customer_id}`}>
          <i className="fa fa-eye me-2"></i>
          {renderName()}
        </Link>
      </td>
      <td>
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${customer.email}`}
          className="text-secondary"
        >
          <i className="fa fa-envelope me-2"></i>
          {customer.email}
        </a>
      </td>
      <td>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://wa.me/52${customer.phone}`}
          className={
            customer.phone !== null ? "text-success" : "text-secondary"
          }
        >
          <i className="fab fa-whatsapp me-2"></i>
          {customer.phone}
        </a>
      </td>
      <td>
        <i className="fa fa-calendar"></i>{" "}
        {customer.birthdate !== null &&
          moment(customer.birthdate).format("DD MMM YYYY")}
      </td>
      <td className="px-0">
        {renderCustomerTags()}
      </td>
      {["super_admin", "admin"].includes(user.role) && (
        <td>
          {"$"}
          {formatMonto(customer.value)}
        </td>
      )}
      {renderExtraFields()}
      {renderCancel()}
    </tr>
  );
};

export default CustomerRow;
