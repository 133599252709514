import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { UserContext } from "../../context/UserContext";

const SecureInput = ({
  smallTitle,
  type,
  handleChange,
  label,
  value,
  hideValue,
  selectOptions
}) => {
  const [editMode, setEditMode] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setAppConfigProperty } = useContext(AppConfigContext);
  const { user } = useContext(UserContext);


  useEffect(() => {
    if (editMode === true) {
      setAppConfigProperty('fileConfigChanged', true);
      
      const timer = setTimeout(() => {
        setEditMode(false);
      }, 10000); 

      setTimerId(timer);
    } 
  }, [editMode]);

  const formatPrivateValue = (value) => {
    if(hideValue && editMode === false && value) {
      const formattedValue = value.slice(-4).padStart(value.length, '*');
      return formattedValue;
    } else {
      return value;
    }
  }

  const startEditing = () => {
    setEditMode(true);
    clearModal();
  }

  const handleEdit = () => {
    if(editMode === false) {
      modalComponent(
        '¿Estas seguro de editar esta configuración?',
        <div className="row">
          <p>Cuidado, realizar cambios en la sección podría causar <span className="bold text-danger">fallos severos.</span></p>
  
          <div className="col-6">
            <button 
              type="button"
              className="btn w-100" 
              onClick={clearModal}
            >
              Cancelar
            </button>
          </div>
  
          <div className="col-6">
            <button 
              type="button"
              className="btn btn-primary w-100"
              onClick={startEditing}
            >
              Continuar
            </button>
          </div>
        </div>
      )
    }
  }

  const handleChangeInput = (event) => {
    handleChange(event);
    clearTimeout(timerId);

    const timer = setTimeout(() => {
      setEditMode(false);
    }, 10000); 
    
    setTimerId(timer);
  }

  const renderInput = () => {
    if(type === 'text') {
      return(
        <input
          disabled={!editMode}
          type={'text'}
          className="form-control"
          value={formatPrivateValue(value)}
          onChange={handleChangeInput}
        />
      )
    }

    if(type === 'select') {
      return(
        <select 
          disabled={!editMode}
          className="form-control "
          value={value}
          onChange={handleChangeInput}
        >
          {
            selectOptions.map(opt => (
              <option key={opt.value} value={opt.value}>{opt.label}</option>
            ))
          }
        </select>
      )
    }
  }

  const renderTitle = () => {
    if(smallTitle) {
      return <h6 className="mb-1 px-0">{label}</h6>
    } else {
      return <h5 className="mb-1 px-0">{label}</h5>
    }
  }

  const renderEditBtn = () => {
    if(user.role === 'super_admin') {
      return(
        <span 
          id="basic-addon2"
          className="input-group-text bg-light cursor-pointer" 
          onClick={handleEdit}
        >
          <i className="fas fa-edit me-2"/>
          Editar
        </span>
      )
    }
  }
  
  return(
    <>
      {renderTitle()}

      <div className="input-group px-0">
        {renderInput()}
        {renderEditBtn()}
      </div>

    </>  
  )
 
}

export default SecureInput;