import React, { useContext, useEffect, useState } from "react";
import CoachForm from "../../components/coaches/CoachForm";
import PanelTitle from "../../components/global/PanelTitle";
import { CoachesContext } from "../../context/CoachesContext";
import { ModalContext } from "../../context/ModalContext";
import CoachesTable from "../../components/coaches/CoachesTable";

const AdminCoaches = () => {
  const [query, setQuery] = useState("");

  const { coaches, getAdminCoaches, deleteCoach } = useContext(CoachesContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getAdminCoaches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCoach = () => {
    modalComponent("Agregar Coach", <CoachForm instructor_id="nueva" />);
  };

  const editCoach = (coach) => {
    modalComponent(
      "Editar Coach",
      <CoachForm instructor_id={coach.instructor_id} />
    );
  };

  const confirmDelete = (coach) => {
    modalComponent(
      "¡Precaución!",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que quieres eliminar a la coach {coach.name}? Esta
          acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteCoach(coach.instructor_id)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Coaches" onClick={addCoach} />
      <div className="card p-2 shadow">
        <input
          type="text"
          value={query}
          className="form-control"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar paquete por nombre..."
        />
        <CoachesTable
          query={query}
          coaches={coaches}
          handleEdit={editCoach}
          handleDelete={confirmDelete}
        />
      </div>
    </div>
  );
};

export default AdminCoaches;
