import React, { useContext } from "react";
import { Router } from "@reach/router";
import AdminClientes from "./admin/AdminClientes";
import AdminPaquetes from "./admin/AdminPaquetes";
import AdminPaqueteForm from "./admin/AdminPaqueteForm";
import AdminClases from "./admin/AdminClases";
import AdminSesiones from "./admin/AdminSesiones";
import AdminAsistentes from "./admin/AdminAsistentes";
import AdminLocations from "./admin/AdminLocations";
import AdminCoaches from "./admin/AdminCoaches";
import AdminSingleUsuario from "./admin/AdminSingleUsuario";
import AdminCategories from "./admin/AdminCategories";
import AdminSingleCategory from "./admin/AdminSingleCategory";
import AdminHome from "./admin/AdminHome";
import AdminClassTypes from "./admin/AdminClassTypes";
import AdminDescuentos from "./admin/AdminDescuentos";
import AdminEspeciales from "./admin/AdminEspeciales";
import AdminAsistentesEspeciales from "./admin/AdminAsistentesEspeciales";
import AdminVideos from "./admin/AdminVideos";
import AdminVideoForm from "./admin/AdminVideoForm";
import AdminCustomerForm from "./admin/AdminCustomerForm";
import AdminUsuarios from "./admin/AdminUsuarios";
import AdminInvoices from "./admin/AdminInvoices";
import AdminPurchases from "./admin/AdminPurchases";
import AdminOnline from "./admin/AdminOnline";
import AdminPresenciales from "./admin/AdminPresenciales";
import AdminPages from "./admin/AdminPages";
import AdminPageForm from "./admin/AdminPageForm";
import { AppConfigContext } from "../context/AppConfigContext";
import AdminVideoTypes from "./admin/AdminVideoTypes";
import AdminSingleReservation from "./admin/AdminSingleReservation";
import AdminAjustes from "./admin/AdminAjustes";
import AdminTestimonios from "./admin/AdminTestimonios";
import AdminSingleTestimonio from "./admin/AdminSingleTestimonio";
import AdminPaymentMethods from "./admin/AdminPaymentMethods";
import AdminClassesRatings from "./admin/AdminClassesRatings";
import AdminVideoRatings from "./admin/AdminVideoRatings";
import AdminTags from "./admin/AdminTags";

const Admin = () => {
  const { single_class_enabled, videos_enabled } = useContext(AppConfigContext);

  const renderSingleClassTabs = () => {
    if (parseInt(single_class_enabled)) {
      return (
        <>
          <AdminClases path="/clases/*" default />
          <AdminSesiones path="/asistentes" />
          <AdminAsistentes path="/asistentes/:single_class_id" />
          <AdminLocations path="/ubicaciones" />
          <AdminEspeciales path="/especiales" />
          <AdminPresenciales path="/presenciales" />
          <AdminAsistentesEspeciales path="/especiales/:class_package_id" />
          <AdminClassesRatings path="/single_classes/ratings" />
        </>
      );
    }
  };

  const renderVideoTabs = () => {
    if (parseInt(videos_enabled)) {
      return (
        <>
          <AdminVideos path="/videos" default />
          <AdminVideoTypes path="/video_types" />
          <AdminVideoForm path="/videos/:video_id" />
          <AdminOnline path="/online" />
          <AdminVideoRatings path="/videos/ratings" />
        </>
      );
    }
  };

  return (
    <Router>
      {renderVideoTabs()}
      {renderSingleClassTabs()}
      <AdminTags path="/tags" />
      <AdminClientes path="/customers" />
      <AdminCustomerForm path="/customer/:customer_id/edit" />
      <AdminSingleUsuario path="/customer/:customer_id" />
      <AdminPaquetes path="/paquetes" />
      <AdminPaqueteForm path="/paquetes/:class_package_id" />
      <AdminCoaches path="/coaches" />
      <AdminCategories path="/categorias" />
      <AdminSingleCategory path="/categorias/:class_category_id" />
      <AdminClassTypes path="/class_types" />
      <AdminHome path="/home" />
      <AdminDescuentos path="/descuentos" />
      <AdminPurchases path="/orders" />
      <AdminUsuarios path="/users" />
      <AdminInvoices path="/subscriptions" />
      <AdminPages path="/pages" />
      <AdminPageForm path="/pages/:page_id" />
      <AdminSingleReservation path="/reservation/:class_reservation_id" />
      <AdminTestimonios path="/testimonios" />
      <AdminSingleTestimonio path="/testimonios/:testament_id" />
      <AdminAjustes path="/ajustes"/>
      <AdminPaymentMethods path="/payment_methods"/>
    </Router>
  );
};

export default Admin;
