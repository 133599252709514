import React, { createContext, useContext, useReducer } from "react";
import AdjuntosReducer from "../reducers/AdjuntosReducer";
import AdjuntosService from "../services/AdjuntosService";
import { AppConfigContext } from "./AppConfigContext";

import {

} from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  adjunto: null,
  adjuntos: null,
};

export const AdjuntosContext = createContext(initialState);

export const AdjuntosProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AdjuntosReducer, initialState);

  const { 
    files_location,
    aws_bucket_name,
    aws_access_key,
    aws_secret_key,
    aws_bucket_region,
  } = useContext(AppConfigContext);
  const { alert } = useContext(ModalContext);

  const getUploadService = () => {
    if(files_location === 'aws') {
      return AdjuntosService.postAwsFile;
    } else {
      return AdjuntosService.postAdjunto;
    }
  }

  const postAdjunto = (file) => {
    const service = getUploadService();
    const formData = AdjuntosService.getFormData(file);
    
    return service(formData)
      .then(res => {
        return res;
      })
      .catch(err => {
        alert(err);
      })
  }

  const getCurrentAwsConfig = () => {
    const config = {
      aws_bucket_name,
      aws_access_key,
      aws_secret_key,
      aws_bucket_region
    }

    return config;
  }

  const testAdjunto = async () => {
    const service = getUploadService();
    const testImage = await fetch('/images/icono.png');
    const blob = await testImage.blob();
    const file = new File([blob], 'test.png', { type: blob.type });

    let formData;
    let data = {};

    if(files_location === 'aws') {
      data = getCurrentAwsConfig();
    }

    data.test = true;
    formData = AdjuntosService.getFormData(file, JSON.stringify(data));

    return service(formData)
      .then(res => {
        return true;
      })
      .catch(err => {
        alert('Hubo un error en la configuración de los archivos, por seguridad los cambios no serán guardados.');
        return false;
      });

  }

  return (
    <AdjuntosContext.Provider
      value={{
        ...state,
        postAdjunto,
        testAdjunto
      }}
    >
      {children}
    </AdjuntosContext.Provider>
  );
};
