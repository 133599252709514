import { useContext, useEffect, useState } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";


const DesignInputs = ({ }) => {
  const [colors, setColors] = useState([]);
  
  const { 
    primary,
    accent,
    dark,
    light,
    gray,
    setAppConfigProperty,
  } = useContext(AppConfigContext);

  useEffect(() => {
    setColors([
      {
        label: 'Primary',
        value: primary
      },
      {
        label: 'Accent',
        value: accent
      },
      {
        label: 'Dark',
        value: dark
      },
      {
        label: 'Light',
        value: light
      },
      {
        label: 'Gray',
        value: gray
      }
    ])
  }, [primary, accent, dark, light, gray]);


  const handleChangeColor = (event, index) => {
    const value = event.target.value;
    const currentColor = colors[index].label.toLowerCase();

    const newColor = {
      ...colors[index],
      value
    }

    const newColors = [...colors];
    newColors[index] = newColor;
    
    setAppConfigProperty(currentColor, value);
  }


  const renderColorInputs = () => {
    return colors?.map((obj, index) => {
      return(
        <div key={obj.label} className="row mb-2">
          <label className="col-2" style={{minWidth: '90px'}} >{obj.label}</label>
          <input
            type={'color'}
            className="form-control p-0"
            style={{
              width: '30px',
              height: '30px',
            }}
            value={obj.value}
            onChange={(event) => handleChangeColor(event, index)}
          />
        </div>
      )
    })
  }


  return(
    <div className="row mb-4">
      <div className="col-12 ">
        <h5 className="mb-1">Diseño</h5>
      </div>

      {renderColorInputs()}
    </div>
  )

}

export default DesignInputs;