import { useCallback, useEffect, useRef, useState } from "react";
import { createReactEditorJS } from "react-editor-js";
import {
  EDITOR_JS_TOOLS,
  parseBlock,
  htmlToBlocks,
} from "../editor/EditorTools";

const ReactEditorJS = createReactEditorJS();

export const EditorHTML = ({ className, defaultBlocks, content, setContent }) => {
  const [documentBlocks, setDocumentBlocks] = useState([]);

  const editorCore = useRef(null);

  useEffect(() => {
    renderContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditorReady = () => {
    setHideBorderClass();
  }

  const setHideBorderClass = () => {
    const editorRef = document.getElementsByClassName('codex-editor');
    
    if(className) {
      editorRef[0]?.classList.add(className);
    }    
  }

  const renderContent = () => {
    if (content.length > 0) {
      setTimeout(() => {
        const currentBlocks = htmlToBlocks(content);
        handleRender(currentBlocks);
      }, 100);
    } else {
      setTimeout(() => {
        handleRender(defaultBlocks);
      }, 100);
    }
  };

  const handleBlocksToHTML = (blocks) => {
    let newContent = "";

    blocks?.forEach((block) => {
      newContent += parseBlock(block);
    });

    setContent(newContent);
  };

  const handleSave = useCallback(async () => {
    if (editorCore.current) {
      const savedData = await editorCore.current?.save();
      setDocumentBlocks(savedData?.blocks);
      handleBlocksToHTML(savedData?.blocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRender = useCallback(async (newBlocks) => {
    if (editorCore.current && newBlocks) {
      await editorCore?.current?.render({ blocks: newBlocks });
      await handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  return (
    <ReactEditorJS
      onReady={handleEditorReady}
      onChange={handleSave}
      onInitialize={handleInitialize}
      defaultValue={{ blocks: documentBlocks }}
      tools={EDITOR_JS_TOOLS}
    />
  );
};
