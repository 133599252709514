import moment from "moment/moment";
import React from "react";

const PaymentMethodsTable = ({ payment_methods, handleEdit, handleDelete }) => {

  const renderPaymentMethods = () => {
    if (Array.isArray(payment_methods)) {
      return payment_methods.map((payment_method) => {
        const lastUpdate = moment(payment_method.updatedAt).format("DD MMM YYYY");
        return (
          <tr key={payment_method.payment_method_id}>
            <td>
              {payment_method.name}
            </td>
            <td className="text-center">
              {lastUpdate}
            </td>
            <td className="text-center" style={{width: 'max-content'}}>
              <button
                className="btn btn-sm btn-outline-dark"
                onClick={() => handleEdit(payment_method)}
              >
                <i className="fa fa-edit"></i>{" "}
                <span className="hide-mobile">Editar</span>
              </button>
              <button
                className="btn btn-sm btn-outline-danger mx-3"
                onClick={() => handleDelete(payment_method)}
              >
                <i className="fa fa-trash"></i>{" "}
                <span className="hide-mobile">Eliminar</span>
              </button>
            </td>
          </tr>
        )
      });
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bold bg-light">
            <td>Nombre</td>
            <td className="text-center">Ultima vez editado</td>
            <td className="text-center" style={{width: 'max-content'}}>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderPaymentMethods()}</tbody>
      </table>
    </div>
  );
};
export default PaymentMethodsTable;
