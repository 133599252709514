import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { Link } from "@reach/router";
import CustomersTable from "../../components/customers/CustomersTable";
import Pagination from "../../components/global/Pagination";

const AdminClientes = () => {
  const [sort, setSort] = useState("name");
  const [field, setField] = useState("");
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState("ASC");
  const [query, setQuery] = useState("");
  const { customers, getAllCustomers } = useContext(CustomerContext);

  useEffect(() => {
    getAllCustomers({ page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    const fetchData = () => {
      getAllCustomers({ query, page, field, sort, direction });
    };

    if (query.length > 0) {
      const timeoutId = setTimeout(fetchData, 500);
      return () => clearTimeout(timeoutId);
    } else {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, field, sort, direction]);

  return (
    <div className="container-fluid">
      <div className="row mx-0 align-items-center mb-3 border-bottom">
        <div className="col-12 col-md-4 ps-0">
          <h1>Clientes</h1>
        </div>
        <div className="col-12 col-md-8 px-0">
          <div className="row">
            <div className="col-6 col-md-8">
              <div className="row">
                <div className="col-8 pr-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
                <div className="col-4 pl-0">
                  <select
                    className="form-control"
                    value={field}
                    onChange={(e) => setField(e.target.value)}
                  >
                    <option value="">Todo</option>
                    <option value="name">Nombre</option>
                    <option value="last_name">Apellidos</option>
                    <option value="email">Correo</option>
                    <option value="phone">Teléfono</option>
                    <option value="tags">Etiquetas</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 text-right">
              <Link
                to="/myadmin/customer/nuevo/edit"
                className="btn btn-primary w-100"
              >
                + Agregar
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card  p-3">
        <CustomersTable
          customers={customers}
          setSort={setSort}
          direction={direction}
          selected={sort}
          setDirection={setDirection}
        />
        <Pagination modifier={setPage} />
      </div>
    </div>
  );
};

export default AdminClientes;
