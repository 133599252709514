import React from "react";
import moment from "moment";
import { navigate } from "@reach/router";

const SingleClassRow = ({ single_class }) => {

  const renderInstructors = () => {
    if (single_class.class_instructors !== null) {
      if (single_class.class_instructors.length > 0) {
        return single_class.class_instructors
          .map(({ instructor }) => instructor?.name)
          .join(",");
      }
    }
    return "N/D";
  };

  const renderLocation = () => {
    if (single_class.location && single_class.location !== null) {
      return single_class.location.name;
    }
  };

  const renderCapacity = () => {
    const capacity = parseInt(single_class.capacity);
    
    if(isNaN(capacity)) {
      return (
        <td>
          {single_class.reservations} /
          <i className="fas fa-infinity ms-2"/>
        </td>
      )
    } else {
      return (
        <td>
          {single_class.reservations} / {single_class.capacity}
        </td>
      )
    }
  }

  return (
    <tr
      className="small align-middle hover-light"
      onClick={() =>
        navigate(`/myadmin/asistentes/${single_class.single_class_id}`)
      }
    >
      <td>
        {moment(single_class.class_date).utc().format("ddd DD MMM, HH:mm")}
      </td>
      <td>{renderInstructors()}</td>
      <td>{renderLocation()}</td>
      {renderCapacity()}
    </tr>
  );
};
export default SingleClassRow;
