import React, { useContext, useEffect } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import AgregarAsistente from "../../components/clases/AgregarAsistente";
import PanelTitle from "../../components/global/PanelTitle";
import { ReservationsContext } from "../../context/ReservationsContext";
import AsistentesTable from "../../components/asistentes/AsistentesTable";
import SingleClassData from "../../components/single_class/SingleClassData";

const AdminAsistentes = ({ single_class_id }) => {
  const { clase, clearClase, postPayment, getAsistentes, updateGuestName } =
    useContext(ClassInstructorContext);

  const { postGear, postAttend } = useContext(ReservationsContext);

  const { eliminarReservacion } = useContext(ReservationsContext);

  const { modalComponent } = useContext(ModalContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    fetchAsistentes();
    return clearClase;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_class_id]);

  const fetchAsistentes = () => {
    getAsistentes(single_class_id);
  };
  const addAsistente = () => {
    modalComponent(
      "Agregar Asistente",
      <AgregarAsistente single_class_id={single_class_id} />
    );
  };

  const confirmCancel = (customer) => {
    modalComponent(
      "¿Cancelar reservacion?",
      <div className="container-fluid">
        <p>
          ¿Estás segura que deseas cancelar la reservacion de {customer.name}?
          Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() =>
            eliminarReservacion(customer.class_reservation_id, () =>
              getAsistentes(single_class_id)
            )
          }
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderClase = () => {
    if (clase && clase !== null) {
      return <SingleClassData single_class={clase} />;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="contiainer-fluid px-3">
      <PanelTitle
        title="Clase Presencial"
        onClick={addAsistente}
        hideButton={user.role === "coach"}
      />
      <div className="card p-3 shadow-sm ">{renderClase()}</div>
      <AsistentesTable
        asistentes={clase !== null ? clase.asistentes : []}
        postPayment={postPayment}
        postAttend={postAttend}
        handleCallback={fetchAsistentes}
        single_class_id={single_class_id}
        updateGuestName={updateGuestName}
        confirmCancel={confirmCancel}
        postGear={postGear}
        user={user}
        clase={clase}
      />
    </div>
  );
};

export default AdminAsistentes;
