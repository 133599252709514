
import { useContext, useEffect, useState } from "react";
import ReactSwitch from "react-switch";
import { AppConfigContext } from "../../context/AppConfigContext";
import { grayImg } from "../../utils";

const HomeSectionResources = () => {
  const appconfig = useContext(AppConfigContext);
  const { setAppConfigProperty, images_inputs, filesBaseSrc } = appconfig;
  const [resources, setResources] = useState({
    hero_image: '',
    hero_video: '',
  });
 
  const heroVideoEnabled = appconfig['hero_resource'] === 'video';
  const heroImageEnabled = appconfig['hero_resource'] === 'image';
 
  useEffect(() => {
    setResources({
      hero_image: `${filesBaseSrc}/${appconfig.hero_image}`,
      hero_video: `${filesBaseSrc}/${appconfig.hero_video}`,
    });
  }, [appconfig.hero_image, appconfig.hero_video]);

  const handleSetHeroResource = (resource) => {
    const onTrue = resource === 'image' ? 'image' : 'video';
    const onFalse = resource === 'image' ? 'video' : 'image';

    return (checked) => {
      if(checked) { 
        setAppConfigProperty('hero_resource', onTrue);
      } else {
        setAppConfigProperty('hero_resource', onFalse);
      }
    }
  }

  const renderHeroImage = () => {
    const src = resources.hero_image?.length > 0 ? resources.hero_image : grayImg;

    return(
      <img 
        className="bg-light border p-0"
        src={src}
        style={{
          width: '500px',
          height: '300px',
          objectFit: 'contain'
        }}
      />
    )
  }

  const renderHeroVideo = () => {
    const src = resources.hero_video?.length > 0 ? resources.hero_video : grayImg;

    return(
      <video 
        autoPlay
        data-autoplay
        playsInline
        loop
        muted
        className="bg-light border p-0"
        src={src}
        style={{
          width: '500px',
          height: '300px',
          objectFit: 'contain'
        }}
      />
    )
  }

  const handleChangeFile = (srcKey) => {
    return (event) => {
      const currentFile = event.target.files[0];
      const fileSrc = URL.createObjectURL(currentFile);

      const fileData = {
        file: currentFile,
        key: srcKey
      }

      setAppConfigProperty('images_inputs', [...images_inputs, fileData]);
      setResources({
        ...resources,
        [srcKey]: fileSrc
      });
    }
  };

  return (
    <div className="row my-5">
      <div className="col-12 col-lg-6">
        <div className="card shadow border p-3">
          <div className="row mx-auto w-100 justify-content-between">
            <label className="bold w-max-content ps-0">Imagen para Hero</label>
            <ReactSwitch
              className="w-max-content pe-0"
              checked={heroImageEnabled}
              onChange={handleSetHeroResource('image')}
            />
          </div>

          <div className="row mx-auto w-100 justify-content-center mt-3">
            {renderHeroImage()}
            <input
              type="file"
              className="form-control mt-3"
              onChange={handleChangeFile('hero_image')}
            />
          </div>

        </div>
      </div>

      <div className="col-12 col-lg-6">
        <div className="card shadow border p-3">
          <div className="row mx-auto w-100 justify-content-between">
            <label className="bold w-max-content ps-0">Video para Hero</label>
            <ReactSwitch
              className="w-max-content pe-0"
              checked={heroVideoEnabled}
              onChange={handleSetHeroResource('video')}
            />
          </div>

          <div className="row mx-auto w-100 justify-content-center mt-3">
            {renderHeroVideo()}
            <input
              type="file"
              className="form-control mt-3"
              onChange={handleChangeFile('hero_video')}
            />
          </div>

        </div>
      </div>
    </div>
  );
};

export default HomeSectionResources;
