import { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import DesignInputs from "./DesignInputs";
import AppImagesInputs from "./AppImagesInputs";


const AppearanceSection = () => {
  const {
    business_name,
    tagline,
    hero_title,
    hero_tagline,
    app_css,
    setAppConfigProperty,
  } = useContext(AppConfigContext);

  const handleChangeAppName = (event) => {
    const name = event.target.value;
    if(name.length <= 50) {
      setAppConfigProperty('business_name', name);
    }
  }

  const handleChangeTagline = (event) => {
    const tagline = event.target.value;
    if(tagline.length <= 115) {
      setAppConfigProperty('tagline', tagline);
    }
  }

  const handleChangeProperty = (event, key) => {
    const value = event.target.value;
    setAppConfigProperty(key, value);
  }
  
  return (
    <>
      <div className="col-12 col-md-6">
        <div className="row w-100 m-auto mb-3">
          <h5 className="mb-1 px-0">Nombre</h5>
          <input
            type={'text'}
            className="form-control"
            value={business_name}
            onChange={handleChangeAppName}
          />
        </div>
          
        <div className="row w-100 m-auto mb-3">
          <h5 className="mb-1 px-0">Tagline</h5>
          <input
            type={'text'}
            className="form-control"
            value={tagline}
            onChange={handleChangeTagline}
          />
        </div>

        <div className="row w-100 m-auto mb-3">
          <h5 className="mb-1 px-0">Titulo de Home</h5>
          <input
            type={'text'}
            className="form-control"
            value={hero_title}
            onChange={(event) => handleChangeProperty(event, 'hero_title')}
          />
        </div>

        <div className="row w-100 m-auto mb-3">
          <h5 className="mb-1 px-0">Tagline de Home</h5>
          <input
            type={'text'}
            className="form-control"
            value={hero_tagline}
            onChange={(event) => handleChangeProperty(event, 'hero_tagline')}
          />
        </div>

        <DesignInputs/>

        <div className="row w-100 m-auto mb-3">
          <h5 className="mb-1 px-0">Estilos CSS</h5>
          <textarea 
            className="form-control"
            style={{height: '150px'}}
            value={app_css}
            onChange={(event) => handleChangeProperty(event, 'app_css')}
          />
        </div>
      </div>

      <div className="col-12 col-md-6">
        <AppImagesInputs/>
      </div>
    </>
  )
}

export default AppearanceSection;