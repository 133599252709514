import React, { useContext, useEffect } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import ScheduleAdmin from "../../components/schedule/ScheduleAdmin";
import PanelTitle from "../../components/global/PanelTitle";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import SingleClassForm from "../../components/single_class/SingleClassForm";
import { LocationsContext } from "../../context/LocationsContext";
import { navigate } from "@reach/router";

const AdminClases = () => {
  const { clearClase, createClase, clase } = useContext(ClassInstructorContext);
  const { getLocations, locations } = useContext(LocationsContext)
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getLocations();
  }, []);

  const handleCancel = () => {
    clearModal();
    clearClase();
  };

  const createLocation = () => {
    navigate('/myadmin/ubicaciones#new=true');
  }

  const addClase = () => {
    if(locations?.length > 0) {
      createClase();
      modalComponent(
        "Agregar Clase",
        <SingleClassForm
          handleCancel={handleCancel}
        />
      );
    } else {
      modalComponent(
        "No existen ubicaciones registradas",
        <div className="row">
          <p>Para crear una clase es necesario tener al menos 1 ubicación registrada.</p>

          <div className="col-12 px-0">
            <div className="row">

              <div className="col-6">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="btn w-100 text-muted"
                >
                  Cancelar
                </button>
              </div>

              <div className="col-6">
                <button 
                  type="button" 
                  className="btn w-100 btn-primary"
                  onClick={createLocation}
                >
                  Registrar Ubicación
                </button>
              </div>

            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title="Clases"
        onClick={addClase}
        hideButton={user.role === "coach"}
      />
      <ScheduleAdmin />
    </div>
  );
};

export default AdminClases;
