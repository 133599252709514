import React from "react";
import ScheduleAdminClass from "./ScheduleAdminClass";
import moment from "moment";

const ScheduleAdminDay = ({ day, clases, handleCallback, isMobile }) => {
  const renderSingleClasses = () => {
    if (Array.isArray(clases)) {
      if (clases.length === 0) {
        return <p className="show-mobile">No hay clases programadas.</p>;
      }

      if(isMobile) {
        clases = clases.sort((a, b) => (a.class_date > b.class_date ? 1 : -1));
      } else {
        clases = clases.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
      }

      return clases.map((clase) => (
        <ScheduleAdminClass
          key={clase.single_class_id}
          singleClass={clase}
          handleCallback={handleCallback}
        />
      ));
    }
  };

  const renderDay = () => {
    const day_string = moment(day.date).utc().format("dd");
    switch (day_string) {
      case "Tu":
        return "MARTES";
      case "We":
        return "MIÉRCOLES";
      case "Th":
        return "JUEVES";
      case "Fr":
        return "VIERNES";
      case "Sa":
        return "SÁBADO";
      case "Su":
        return "DOMINGO";
      default:
        return "LUNES";
    }
  };

  return (
    <div className="schedule-col px-2 text-center border bg-light">
      <div className="show-mobile">
        <div className="row mx-0 pt-2">
          <div className="container-fluid text-dark">
            {moment(day.date).utc().format("DD")}
          </div>
        </div>
        <div className="row mx-0 my-3 bg-dark text-white">
          <div className="container-fluid text-center">{renderDay()}</div>
        </div>
      </div>
      <div className="bg-light px-2">{renderSingleClasses()}</div>
    </div>
  );
};

export default ScheduleAdminDay;
