import React, { useState } from "react";

const LeaderboardTable = ({ customers }) => {
  const [sort, setSort] = useState("reservations");

  const renderCustomers = () => {
    if (Array.isArray(customers)) {
      let customersRender = [...customers];
      customersRender = customersRender.sort((a, b) =>
        parseInt(a[sort]) > parseInt(b[sort]) ? -1 : 1
      );
      return customersRender.map((customer) => (
        <tr key={customer.customer_id}>
          <td>{customer.name}</td>
          <td>{customer.last_name}</td>
          <td>{customer.email}</td>
          <td>{customer.reservations}</td>
          <td>{customer.attended}</td>
        </tr>
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light border bold p-2">
            <th>Nombre</th>
            <th>Apellidos</th>
            <th>Email</th>
            <th>
              Reservadas{" "}
              <button
                className="btn btn-sm mx-2 py-0 px-0"
                onClick={() => setSort("reservations")}
              >
                <i
                  className={`fa fa-chevron-${
                    sort === "reservations" ? "down" : "up"
                  }`}
                ></i>
              </button>
            </th>
            <th>
              Asistencias
              <button
                className="btn btn-sm mx-2 py-0 px-0"
                onClick={() => setSort("attended")}
              >
                <i
                  className={`fa fa-chevron-${
                    sort === "attended" ? "down" : "up"
                  }`}
                ></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>{renderCustomers()}</tbody>
      </table>
    </div>
  );
};

export default LeaderboardTable;
