import React, { createContext, useReducer, useContext } from "react";
import CoachesReducer from "../reducers/CoachesReducer";
import AdjuntosService from "../services/AdjuntosService";
import CoachesService from "../services/CoachesService";
import {
  COACHES_RECIBIDAS,
  CREATE_COACH,
  SET_COACH,
  SET_PROPIEDAD_COACH,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import { AdjuntosContext } from "./AdjuntosContext";

const initialState = {
  coaches: null,
  coach: null,
};

export const CoachesContext = createContext(initialState);

export const CoachesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CoachesReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);
  const { postAdjunto } = useContext(AdjuntosContext);


  const setCoaches = (payload) => {
    dispatch({ type: COACHES_RECIBIDAS, payload });
  }

  const getCoaches = () => {
    CoachesService.getCoaches().then((res) => {
      const { instructors } = res.data;
      dispatch({ type: COACHES_RECIBIDAS, payload: instructors });
    });
  };

  const getAdminCoaches = () => {
    CoachesService.getAdminCoaches().then((res) => {
      const { instructors } = res.data;
      dispatch({ type: COACHES_RECIBIDAS, payload: instructors });
    });
  };

  const getCoach = (instructor_id) => {
    CoachesService.getSingleCoach(instructor_id).then((res) => {
      const { instructor } = res.data;
      dispatch({ type: SET_COACH, payload: instructor });
    });
  };

  const setCoach = (coach) => {
    dispatch({ type: SET_COACH, payload: coach });
  };

  const createCoach = () => {
    dispatch({ type: CREATE_COACH });
  };

  const setPropiedadCoach = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_COACH, payload: { key, value } });
  };

  const postCoach = async (coach) => {
    if (isNaN(coach.instructor_id)) {
      if (coach.picture && coach.picture !== null) {

        postAdjunto(coach.picture).then((res) => {
          const { file_id } = res.data;
          coach.file_id = file_id;

          if (coach.customer) {
            coach.user_id = coach.customer.customer_id;
          }

          CoachesService.postCoach(coach).then(() => {
            getCoaches();
            clearModal();
            success("¡Coach guardado!");
          });
        });
      } else {
        if (coach.customer) {
          coach.user_id = coach.customer.customer_id;
        }
        CoachesService.postCoach(coach).then(() => {
          getCoaches();
          clearModal();
          success("¡Coach guardado!");
        });
      }
    } else {
      if (coach.picture && coach.picture !== null) {
        
        postAdjunto(coach.picture).then((res) => {
          coach.file_id = res.data.file_id;
          if (coach.customer) {
            coach.user_id = coach.customer.customer_id;
          }

          CoachesService.putCoach(coach).then(() => {
            getCoaches();
            clearModal();
            success("¡Coach guardado!");
          });
        });
      } else {
        if (coach.customer) {
          coach.user_id = coach.customer.customer_id;
        }
        CoachesService.putCoach(coach).then(() => {
          getCoaches();
          clearModal();
          success("¡Coach guardado!");
        });
      }
    }
  };

  const deleteCoach = (instructor_id) => {
    CoachesService.deleteCoach(instructor_id).then(() => {
      hideModal();
      getCoaches();
    });
  };

  return (
    <CoachesContext.Provider
      value={{
        ...state,
        getCoach,
        getAdminCoaches,
        setCoach,
        setCoaches,
        postCoach,
        getCoaches,
        createCoach,
        deleteCoach,
        setPropiedadCoach,
      }}
    >
      {children}
    </CoachesContext.Provider>
  );
};
