import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import PaymentMethodsTable from "../../components/payment_methods/PaymentMethodsTable";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import { ModalContext } from "../../context/ModalContext";
import PaymentMethodForm from "../../components/payment_methods/PaymentMethodForm";


const AminPaymentMethods = ({  }) => {
  const {
    getPaymentMethods, 
    payment_methods, 
    setPaymentMethod,
    createPaymentMethod,
    setPropiedadPaymentMethod,
    postPaymentMethod,
    deletePaymentMethod
  } = useContext(PaymentMethodsContext);
 
  const { modalComponent, clearModal } = useContext(ModalContext);


  useEffect(() => {
    getPaymentMethods();
  }, []);

  const handleCreate = () => {
    createPaymentMethod();
    modalComponent(
      "Crear nuevo Método de Pago",
      <PaymentMethodForm
        modifier={setPropiedadPaymentMethod}
        handleSave={postPaymentMethod}
        handleCancel={() => clearModal()}
      />
    );
  }

  const handleEdit = (payment_method) => {
    setPaymentMethod(payment_method);
    modalComponent(
      "Editar Método de Page",
      <PaymentMethodForm
        modifier={setPropiedadPaymentMethod}
        handleSave={postPaymentMethod}
        handleCancel={() => clearModal()}
      />
    );
  
  }

  const handleDelete = (payment_method) => {
    const { name, payment_method_id } = payment_method;

    modalComponent(
      "Eliminar Método de Pago",
      <form 
        onSubmit={(e) => {
          e.preventDefault();
          deletePaymentMethod(payment_method_id);
        }}
      >
        <p>
          ¿Esta seguro de elminar el siguiente método de pago: 
          <span className="bold">{name}</span>?
        </p>

        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={clearModal}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn w-100 btn-danger">
              <i className="fas fa-trash me-2"/>
              Eliminar
            </button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <div className="contiainer-fluid align-self-center px-3 position-relative overflow-hidden">
      <PanelTitle title="Métodos de Pago" onClick={handleCreate}  />

      <div className="card">
        <PaymentMethodsTable
          payment_methods={payment_methods}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </div>
      
      
    </div>
  );
};

export default AminPaymentMethods;
