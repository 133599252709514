import { useContext } from "react";
import { EditorHTML } from "../common/EditorHTML";
import { AppConfigContext } from "../../context/AppConfigContext";

const LegalSection = () => {
  const appconfig = useContext(AppConfigContext);
  const { 
    setAppConfigProperty 
  } = useContext(AppConfigContext);


  const handleChangePageContent = (key, content) => {
    setAppConfigProperty(key, {
      ...appconfig[key],
      content
    });
  }

  const renderPrivacyPage = () => {
    const { privacyPage } = appconfig;

    if(privacyPage?.content.length > 0) {
      return(
        <EditorHTML
          content={privacyPage.content}
          setContent={(content) => {
            handleChangePageContent('privacyPage', content);
          }}
        />
      )
    }
  }

  const renderTermsPage = () => {
    const { termsPage } = appconfig;
    if(termsPage?.content.length > 0) {
      return(
        <EditorHTML
          content={termsPage.content}
          setContent={(content) => {
            handleChangePageContent('termsPage', content);
          }}
        />
      )
    }
  }

  return (
    <>
      <div className="col-6 ps-0 mb-3">
        <h4>Aviso de Privacidad</h4>
        <div className="card p-2" style={{cursor: 'text'}}>
          {renderPrivacyPage()}
        </div>
      </div>

      <div className="col-6 pe-0 mb-3">
        <h4>Terminos y condiciones</h4>
        <div className="card p-2" style={{cursor: 'text'}}>
          {renderTermsPage()}
        </div>
      </div>

    </>
  )
}

export default LegalSection;