import { getArgs } from "../utils";
import api from "./api";

const route = "/class_reservations";

const ReservationsService = {
  getMyReservations: () => api.get(route),
  getReservationsRatings: (filters) =>
  api.get(`${route}/ratings?${getArgs(filters)}`),
  getSingleReservation: (class_reservation_id) =>
    api.get(`${route}/${class_reservation_id}`),
  postReservation: (class_reservation) =>
    api.post(route, { ...class_reservation }),
  putReservacion: (class_reservation) =>
    api.put(route, { ...class_reservation }),
  postAttend: (class_reservation_id, attend) =>
    api.post(`${route}/attend/${class_reservation_id}`, { attend }),
  postGear: (class_reservation_id, gear) =>
    api.post(`${route}/gear/${class_reservation_id}`, { gear }),
  cancelReservation: (class_reservation_id) =>
    api.delete(`${route}/admin/${class_reservation_id}`),
};

export default ReservationsService;
