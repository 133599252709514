import {

} from "../types/adjuntos";

const AdjuntosReducer = (state, { type, payload }) => {
  switch (type) {

    default:
      return { ...state };
  }
};

export default AdjuntosReducer;
