import React, { useContext } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import LeaderboardTable from "../../components/analytics/LeaderboardTable";

const AnaliticasLeaderboards = () => {
  const { leaderboard, getLeaderboard } = useContext(AnaliticasContext);

  const fetchLeaderboard = (start_date, end_date) => {
    getLeaderboard({ start_date, end_date });
  };

  const renderLeaderboard = () => {
    if (Array.isArray(leaderboard)) {
      if (leaderboard.length === 0) {
        return <p>No hay reservaciones registradas.</p>;
      }
      return <LeaderboardTable customers={leaderboard} />;
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Leaderboard" callback={fetchLeaderboard} />
      <div className="card p-3 shadow">{renderLeaderboard()}</div>
    </div>
  );
};

export default AnaliticasLeaderboards;
