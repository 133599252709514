
import React, { useContext, useEffect } from "react";
import { RefundsContext } from "../../context/RefundsContext";
import { CustomerContext } from "../../context/CustomerContext";

const PurchaseRefundForm = ({ invoice, purchase, handleCancel }) => {
  const { getCustomer } = useContext(CustomerContext);
  const { 
    loading, 
    refund, 
    setPropertyRefund, 
    setRefund, 
    saveRefund,
    saveSubscriptionRefund
  } = useContext(RefundsContext);

  useEffect(() => {
    setRefundData();
  }, []);

  const setRefundData = () => {
    if(purchase) {
      setRefund({
        purchase_id: purchase.purchase_id,
        customer_id: purchase.customer_id,
        amount_refunded: purchase.amount,
        reason: 'requested_by_customer'
      });
    }

    if(invoice) {
      setRefund({
        invoice_id: invoice.invoice_id,
        customer_id: invoice.customer_id,
        amount_refunded: invoice.amount,
        reason: 'requested_by_customer'
      });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const amountFormatted = parseFloat(refund.amount_refunded);
    refund.amount_refunded = amountFormatted;
    handlePostRefund();
  };

  const handlePostRefund = () => {
    if(purchase){
      saveRefund(refund, () => getCustomer(purchase.customer_id));
    }

    if(invoice) {
      saveSubscriptionRefund(refund, () => getCustomer(invoice.customer_id));
    }
  }

  return (
    <div className="container-fluid ">
      <form onSubmit={handleSubmit}>
        <p>
          Los reembolsos tardan entre 5 a 10 días para aparecer en el estado de cuenta del cliente. 
          La tarifa de Stripe por el pago original no será devuelta, pero no hay ninguna otra tarifa por el reembolso.
        </p>
        <label className="bold">Cantidad de Reembolso</label>
        <input
          type="number"
          min={0}
          value={refund.amount_refunded}
          className="form-control mb-3"
          onChange={(e) => {
            const value = e.target.value;
            const amount = purchase ? purchase.amount : invoice.amount;

            if(parseFloat(value) > amount) {
              return setPropertyRefund("amount_refunded", amount);
            }
            
            const isDecimal = value.includes('.');
            const decimalRegex = /^\d+(\.\d{1,2})?$/;

            if(isDecimal) {
              if(decimalRegex.test(value)) {
                setPropertyRefund("amount_refunded", e.target.value);
              }

            } else {
              setPropertyRefund("amount_refunded", e.target.value);
            }
          }}
        />
        <label className="bold">Razón de Reembolso</label>
        <select
          value={refund.reason}
          className="form-control mb-3"
          onChange={(e) => setPropertyRefund("reason", e.target.value)}
        >
          <option value={'requested_by_customer'}>Solicitado por Cliente</option>
          <option value={'duplicate'}>Pago Duplicado</option>
          <option value={'fraudulent'}>Fraudulento</option>
        </select>

        <div className="row">
          <div className="col-6">
            <button 
              type="button" 
              className="btn text-muted w-100"
              onClick={handleCancel}
            >
              CANCELAR
            </button>
          </div>

          <div className="col-6">
            <button 
              disabled={loading}
              type="submit" 
              className="btn btn-primary w-100"
            >
              Reembolsar
            </button>
          </div>
        </div>
       
      </form>
    </div>
  );
};

export default PurchaseRefundForm;
