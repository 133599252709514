import React from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";
import StatusBadge from "../common/StatusBadge";
import moment from "moment";

const FreeTrialRow = ({ purchase }) => {
  const { customer } = purchase;

  const renderAmount = () => {
    if (purchase.invoices.length <= 1) {
      return 0;
    }
    let completedInvoices = purchase.invoices.filter(
      (invoice) => invoice.status === "completed"
    );
    let total = 0;
    completedInvoices.forEach((invoice) => {
      total += parseFloat(invoice.amount);
    });
    return formatMonto(total);
  };

  const renderName = () => {
    if (
      (customer.name !== null && customer.name !== "") ||
      (customer.last_name !== null && customer.last_name !== "")
    ) {
      return `${customer.name} ${customer.last_name}`;
    }
    return "N/D";
  };

  return (
    <tr className="p-2 border-bottom small align-middle hover-light">
      <td className="td-id">{purchase.purchase_id}</td>
      <td>
        <Link to={`/myadmin/customer/${customer.customer_id}`}>
          <i className="fa fa-eye me-2"></i>
          {renderName()}
        </Link>
      </td>
      <td>
        <StatusBadge status={purchase.status} date={purchase.updatedAt} />
      </td>
      <td>
        {"$"}
        {renderAmount()}
      </td>
      <td>{moment(customer.expiration_date).format("DD MMM YYYY")}</td>
    </tr>
  );
};

export default FreeTrialRow;
