import React from "react";
import PurchaseRow from "./PurchaseRow";

const columns = [
  {
    label: "Tipo",
    name: "type",
  },
  {
    label: "Cliente",
    name: "customer",
  },
  {
    label: "Etiquetas",
    name: "tags",
  },
];

const PurchasesTable = ({
  user,
  purchases,
  hideColumns,
  editExpiration,
  confirmCancel,
  confirmRevoke,
}) => {
  const renderColumns = () => {
    let columnsRender = columns;
    if (Array.isArray(hideColumns)) {
      columnsRender = columns.filter((col) => !hideColumns.includes(col.name));
    }
    return columnsRender.map((col, index) => <td key={index}>{col.label}</td>);
  };

  const renderPurchases = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay compras registradas.</td>
          </tr>
        );
      }
      purchases.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
      
        return dateB - dateA;
      });
      
      return purchases.map((purchase) => (
        <PurchaseRow
          user={user}
          paquete={purchase}
          hideColumns={hideColumns}
          key={purchase.purchase_id}
          editExpiration={editExpiration}
          confirmCancel={confirmCancel}
          confirmRevoke={confirmRevoke}
        />
      ));
    }
  };
  return (
    <div className="table-responsive" style={{minHeight: '200px'}}>
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            {renderColumns()}
            <td>Paquete</td>
            <td>Fecha</td>
            <td>Precio</td>
            <td>Corte / Sig.</td>
            <td>Método Pago</td>
            <td>Estado</td>
            <td>Descuento</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderPurchases()}</tbody>
      </table>
    </div>
  );
};

export default PurchasesTable;
