import { useContext, useEffect, useState } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import ReactSwitch from "react-switch";


const ContactSection = () => {
  const {
    location,
    email,
    facebook,
    instagram,
    tiktok,
    twitter,
    youtube,
    pinterest,
    linkedin,
    whatsapp,
    phone,
    whatsapp_widget,

    setAppConfigProperty
  } = useContext(AppConfigContext);

  useEffect(() => {
  }, []);


  return (
    <>
      <div className="col-12 px-0">
        <div className="row w-100">
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Ubicación</h5>
            <input
              className="form-control"
              type="text"
              value={location}
              onChange={(event) => setAppConfigProperty('location', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Correo Electrónico</h5>
            <input
              className="form-control"
              type="text"
              value={email}
              onChange={(event) => setAppConfigProperty('email', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Facebook</h5>
            <input
              className="form-control"
              type="text"
              value={facebook}
              onChange={(event) => setAppConfigProperty('facebook', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Instagram</h5>
            <input
              className="form-control"
              type="text"
              value={instagram}
              onChange={(event) => setAppConfigProperty('instagram', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">TikTok</h5>
            <input
              className="form-control"
              type="text"
              value={tiktok}
              onChange={(event) => setAppConfigProperty('tiktok', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Twitter</h5>
            <input
              className="form-control"
              type="text"
              value={twitter}
              onChange={(event) => setAppConfigProperty('twitter', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Youtube</h5>
            <input
              className="form-control"
              type="text"
              value={youtube}
              onChange={(event) => setAppConfigProperty('youtube', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Pinterest</h5>
            <input
              className="form-control"
              type="text"
              value={pinterest}
              onChange={(event) => setAppConfigProperty('pinterest', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">LinkedIn</h5>
            <input
              className="form-control"
              type="text"
              value={linkedin}
              onChange={(event) => setAppConfigProperty('linkedin', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Whatsapp</h5>
            <input
              className="form-control"
              type="text"
              value={whatsapp}
              onChange={(event) => setAppConfigProperty('whatsapp', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Teléfono Fijo</h5>
            <input
              className="form-control"
              type="text"
              value={phone}
              onChange={(event) => setAppConfigProperty('phone', event.target.value)}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <h5 className="mb-1">Botón de Whatsapp Flotante</h5>
            <ReactSwitch
              checked={parseInt(whatsapp_widget) ? true : false}
              onChange={(checked) => {
                let value;
                checked ? value = 1 : value = 0;
                setAppConfigProperty('whatsapp_widget', value);
              }}
            />
          </div>
        </div>
        

      </div>
    </>
  )
}

export default ContactSection;