import { useContext } from "react";
import { PagesContext } from "../../context/PagesContext";

export default function useCrudSettingsPages() {
  const { getPageBySlug, postPage } = useContext(PagesContext);


  const getPrivacyPoliticsPage = async () => {
    const res = await getPageBySlug('privacy-advice');
    const { data } = res;
    
    if(data.page === null || data.page === undefined) {
      const newPage = await createPrivacyPoliticsPage();
      return newPage.data.page;
    } else {
      return data.page;
    }
  }

  const createPrivacyPoliticsPage = async () => {
    return await postPage({
      title: 'Aviso de Privacidad',
      slug: 'privacy-advice',
      description: '',
      content: ''
    });
  }

  const getTermsPage = async () => {
    const res = await getPageBySlug('terms');
    const data = res.data;

    if(data.page === null || data.page === undefined) {
      const newPage = await createTermsPage();
      return newPage.data.page;
    } else {
      return data.page;
    }
  }

  const createTermsPage = async () => {
    return await postPage({
      title: 'Terminos y Condiciones',
      slug: 'terms',
      description: '',
      content: ''
    });
  }

  return {
    getPrivacyPoliticsPage,
    getTermsPage
  }
}