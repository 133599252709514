import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import { navigate } from "@reach/router";
import { TestimoniosContext } from "../../context/TestimoniosContext";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import { grayImg } from "../../utils";


const AdminTestimonios = ({  }) => {
  const { 
    testimonios, 
    getAllTestimonios, 
    setTestimonios, 
    updateTestimonio,
    deleteTestimonio
  } = useContext(TestimoniosContext);

  useEffect(() => {
    getAllTestimonios();
  }, []);


  const handleCreateTestimonio = () => {
    navigate('/myadmin/testimonios/new');
  }

  const handleEditTestimonio = (testament_id) => {
    navigate(`/myadmin/testimonios/${testament_id}`);
  }

  const handleDeleteTestimonio = (testament_id) => {
    deleteTestimonio(testament_id)
      .then(res => {
        getAllTestimonios();
      });
  }

  const setTestimoniosOrder = (updatedTestimonios) => {
    const orderedTestimonios = updatedTestimonios.map((obj, index) => {
      return {
        ...obj,
        order: index + 1
      }
    });

    return orderedTestimonios;
  }

  const updateTestimoniosOrder = (updatedTestimonios) => {
    updatedTestimonios.map(obj => {
      const testament_id = obj.testament_id;
      const order = obj.order;

      return new Promise((resolve, reject) => {
        updateTestimonio(testament_id, { order })
        .then(resolve)
        .catch(reject);
      });
    });
  }

  const onSortEnd = (oldIndex, newIndex) => {
    const updatedIndex = arrayMove(testimonios, oldIndex, newIndex);
    const updatedOrder = setTestimoniosOrder(updatedIndex);
    updateTestimoniosOrder(updatedOrder);
    setTestimonios(updatedOrder);
  };

  const renderSortableItem = (item) => {
    const customerImg = item.customer_file?.src;
    const imgSrc = customerImg ? customerImg : grayImg;
    const status = item.show_home ? 'Mostrando en Home' : 'Oculto';

    return (
      <SortableItem key={item.testament_id}>
        <div className="sortable-item row mx-auto py-1">
          <div className="col-4 mx-auto">
            <img 
              alt="user-img"
              width={70}
              height={70}
              style={{borderRadius: '50%'}}
              className="bg-light border me-2"
              src={imgSrc}
            />
            {item.customer_name}
          </div>
          <div className="col-2 text-center">{item.rating}</div>
          <div className="col-3 text-center">{status}</div>
          <div className="col-3 text-center">
            <button
              className="btn btn-sm btn-outline-dark me-2"
              onClick={() => handleEditTestimonio(item.testament_id)}
            >
              <i className="fa fa-edit"></i>
            </button>

            <button
              className="btn btn-sm btn-outline-danger me-2"
              onClick={() => handleDeleteTestimonio(item.testament_id)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </SortableItem>
    )
    
  }

  const renderTestaments = () => {
    return (
      <SortableList
        onSortEnd={onSortEnd}
        className="sortable-list position-relative border border-top-0 "
        as="div"
        draggedItemClassName="dragged"
      >
        {testimonios.map((item) => {
          return renderSortableItem(item);
        })}
      </SortableList>
    );
  }
  
  return (
    <div className="contiainer-fluid px-3">
      <PanelTitle title="Testimonios" onClick={handleCreateTestimonio}  />
        <div className="table-responsive mt-3 bg-white ">
          <div className="row w-100 mx-auto border bg-light align-items-center" style={{height: '45px'}}>
            <div className="col-4 bold" style={{height: 'max-content'}}>
              Cliente
            </div>
            <div className="col-2 bold text-center" style={{height: 'max-content'}}>
              Calificación
            </div>
            <div className="col-3 bold text-center" style={{height: 'max-content'}}>
              Estatus
            </div>
            <div className="col-3 bold text-center" style={{height: 'max-content'}}>
              Acciones
            </div>
          </div>
          {renderTestaments()}
        </div>
    </div>
  );
};

export default AdminTestimonios;
