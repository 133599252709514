import { useContext, useEffect, useState } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";


const AppImagesInputs = () => {
  const { 
    navbar_logo, 
    favicon,  
    social_media_image,
    S3_ENDPOINT,
    setAppConfigProperty,
    images_inputs
  } = useContext(AppConfigContext);

  useEffect(() => {
    setAppConfigProperty('images_inputs', [
      {
        key: 'navbar_logo',
        label: 'Logo',
        description: '',
        src: `${S3_ENDPOINT}/${navbar_logo}`,
        file: null
      },
      {
        key: 'favicon',
        label: 'Favicon',
        description: '512x512 píxeles',
        src: `${S3_ENDPOINT}/${favicon}`,
        file: null
      },
      {
        key: 'social_media_image',
        label: 'Imagen para Redes Sociales',
        description: '1200x630 píxeles',
        src: `${S3_ENDPOINT}/${social_media_image}`,
        file: null
      },
    ]);
    
  }, [navbar_logo, favicon, social_media_image]);


  const handleChangeImage = (event, index) => {
    const currentFile = event.target.files[0];

    if(currentFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const currentSrc = e.target.result;
        const newInputs = [...images_inputs];
  
        newInputs[index] = {
          ...newInputs[index],
          src: currentSrc,
          file: currentFile
        }
  
        setAppConfigProperty('images_inputs', newInputs);
      };
  
      reader.readAsDataURL(currentFile);
    }
    
  }

  const renderImageInputs = () => {
    if(!images_inputs) return null;


    return images_inputs?.map((input, index) => {

      const renderDescription = () => {
        if(input.description) {
          return <label className="mb-1 px-0">{input.description}</label>
        }
      }

      return(
        <div 
          key={input.key} 
          className="row mb-4 w-100 m-auto"
        >
          <h5 className="mb-1 px-0">{input.label}</h5>
          {renderDescription()}
          <img 
            alt={`${input.label} img`}
            className="p-0 mx-auto mb-3"
            src={input.src} 
            style={{
              maxWidth: '256px',
              maxHeight: '256px',
              objectFit: 'contain'
            }}
          />

          <input 
            className="form-control" 
            type="file" 
            accept="image/*"
            onChange={(event) => {
              handleChangeImage(event, index)
            }}
          />
        </div>
      )
    });
  }
  

  return renderImageInputs();
}

export default AppImagesInputs;