import { useContext, useEffect, useState } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import ReactSwitch from "react-switch";
import { getValue } from "../../utils";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";

const HomeSectionsForm = () => {
  const [sections, setSections] = useState([]);
  const appconfig = useContext(AppConfigContext);
  const { setAppConfigProperty } = appconfig;

  useEffect(() => {
    setInitialData();
  }, []);

  const setInitialData = () => {
    const initialSections = [
      {
        title: "Hero",
        enabledKey: "hero_enabled",
        orderKey: "hero_order",
        order: appconfig["hero_order"],
      },
      {
        title: "About",
        enabledKey: "about_enabled",
        orderKey: "about_order",
        order: appconfig["about_order"],
      },
      {
        title: "Tipos de Clase",
        enabledKey: "class_types_enabled",
        orderKey: "class_types_order",
        order: appconfig["class_types_order"],
      },
      {
        title: "Coaches",
        enabledKey: "coaches_enabled",
        orderKey: "coaches_order",
        order: appconfig["coaches_order"],
      },
      {
        title: "Paquetes",
        enabledKey: "packages_enabled",
        orderKey: "packages_order",
        order: appconfig["packages_order"],
      },
      {
        title: "Clases Presenciales",
        enabledKey: "schedule_enabled",
        orderKey: "schedule_order",
        order: appconfig["schedule_order"],
      },
    ];

    const orderedSections = initialSections.sort((a, b) => a.order - b.order );
    setSections(orderedSections);
  }

  useEffect(() => {
    if(Array.isArray(sections)) {
      sections.map(section => {
        setAppConfigProperty(section.orderKey, section.order);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  const getSectionsOrder = (updatedSections) => {
    const orderedSections = updatedSections.map((obj, index) => {
      return {
        ...obj,
        order: index + 1,
      };
    });

    return orderedSections;
  };

  const onSortEnd = (oldIndex, newIndex) => {
    const updatedIndex = arrayMove(sections, oldIndex, newIndex);
    const updatedOrder = getSectionsOrder(updatedIndex);
    setSections(updatedOrder);
  };

  const renderSortableItem = (item) => {
    const enabledKey = item.enabledKey;

    return (
      <SortableItem key={item.orderKey}>
        <div className="sortable-item row justify-content-between px-2 mx-auto py-1">
          <h5 className="mb-1 px-0 w-max-content">{item.title}</h5>
          <div className="w-max-content px-0">
            <ReactSwitch
              checked={getValue(appconfig, enabledKey, "boolean")}
              onChange={(checked) => {
                let value;
                checked ? (value = 1) : (value = 0);
                setAppConfigProperty(enabledKey, value);
              }}
            />
          </div>
        </div>
      </SortableItem>
    );
  };

  const renderHomeSections = () => {
    return (
      <SortableList
        onSortEnd={onSortEnd}
        className="sortable-list position-relative border border-top-0 "
        as="div"
        draggedItemClassName="dragged"
      >
        {sections.map((item) => {
          return renderSortableItem(item);
        })}
      </SortableList>
    );
  };

  return (
    <div className="table-responsive mt-3 bg-white ">
      <div
        className="row w-100 mx-auto border bg-light align-items-center"
        style={{ height: "45px" }}
      >
        <div
          className="col-6 bold text-start"
          style={{ height: "max-content" }}
        >
          Secciones del Home
        </div>
      </div>
      {renderHomeSections()}
    </div>
  );
};

export default HomeSectionsForm;
