import React, { useEffect, useContext } from "react";
import LocationsTable from "../../components/locations/LocationsTable";
import LocationForm from "../../components/locations/LocationForm";
import { LocationsContext } from "../../context/LocationsContext";
import PanelTitle from "../../components/global/PanelTitle";
import { ModalContext } from "../../context/ModalContext";

const AdminLocations = () => {
  const {
    locations,
    setLocation,
    getLocations,
    postLocation,
    createLocation,
    deleteLocation,
    setPropiedadLocation,
  } = useContext(LocationsContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getLocations();
    if(window.location.hash === '#new=true') addLocation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if(locations && locations.length <= 0) {
      addLocation();
    }
  }, [locations]);

  const editLocation = (location) => {
    setLocation(location);
    modalComponent(
      "Editar Ubicación",
      <LocationForm
        modifier={setPropiedadLocation}
        postLocation={postLocation}
        handleCancel={clearModal}
      />
    );
  };

  const addLocation = () => {
    createLocation();
    modalComponent(
      "Agregar Ubicación",
      <LocationForm
        modifier={setPropiedadLocation}
        postLocation={postLocation}
        handleCancel={clearModal}
      />
    );
  };

  const confirmDelete = (location) => {
    modalComponent(
      "Cuidado",
      <div className="container-fluid px-0">
        <p className="mb-2"> 
          ¿Estás segura que deseas eliminar la ubicación {location.name}?
        </p>
        <b className="">Todas las clases registradas con esta ubicación serán eliminadas. Esta acción NO puede deshacerse</b>
          
        <div className="row mt-3">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn w-100 btn-danger"
              onClick={() => deleteLocation(location.location_id)}
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderUbicaciones = () => {
    if (Array.isArray(locations)) {
      return (
        <LocationsTable
          locations={locations}
          handleEdit={editLocation}
          handleDelete={confirmDelete}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Ubicaciones" onClick={addLocation} />
      <div className="card shadow p-3">{renderUbicaciones()}</div>
    </div>
  );
};

export default AdminLocations;
