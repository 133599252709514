import React, { useContext, useEffect, useState } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import Chart from "react-apexcharts";
import moment from "moment";

const AnaliticasVideos = () => {
  const [fechaInicio, setFechaInicio] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [fechaFin, setFechaFin] = useState(
    moment().startOf("month").add(1, "month").format("YYYY-MM-DD")
  );
  const { favorites, playbacks, last, hours, getVideos } =
    useContext(AnaliticasContext);

  useEffect(() => {
    getVideos(fechaInicio, fechaFin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicio, fechaFin]);

  const renderChart = () => {
    if (hours && hours !== null) {
      let data = [...hours];
      data = hours.map(({ playbacks, hour }) => ({
        playbacks,
        hour: hour - 6 < 0 ? hour - 6 + 24 : hour - 6,
      }));
      data.sort((a, b) => (a.hour < b.hour ? -1 : 1));
      return (
        <Chart
          type="bar"
          height="300"
          options={{
            colors: ["#ffc0e1"],
            xaxis: {
              categories: data.map(({ hour }) => hour),
            },
          }}
          series={[
            {
              name: "Reproducciones por Hora",
              data: data.map(({ playbacks }) => playbacks),
            },
          ]}
        />
      );
    }
  };

  const renderPlaybacks = () => {
    if (playbacks && playbacks !== null) {
      return playbacks.map((video) => (
        <div key={video.video_id} className="row my-2">
          <div className="col-3">{video.name}</div>
          <div className="col-2">{video.instructor_name}</div>
          <div className="col-2">
            {moment(video.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="col-2">{video.playbacks}</div>
          <div className="col-2">{video.unique_playbacks}</div>
        </div>
      ));
    }
  };

  const renderUltimas = () => {
    if (last && last !== null) {
      return last.map((video) => (
        <div key={video.video_id} className="row my-2">
          <div className="col-3">{video.name}</div>
          <div className="col-2">{video.instructor_name}</div>
          <div className="col-2">
            {moment(video.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="col-2">{video.playbacks}</div>
          <div className="col-2">{video.unique_playbacks}</div>
        </div>
      ));
    }
  };

  const renderFavorites = () => {
    if (favorites && favorites !== null) {
      return favorites.map((favorito) => (
        <div key={favorito.video_id} className="row my-2">
          <div className="col-3">{favorito.name}</div>
          <div className="col-3">{favorito.instructor_name}</div>
          <div className="col-3">
            {moment(favorito.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="col-3">{favorito.favorites}</div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row border-bottom pb-2 mb-2">
        <div className="col-6">
          <h1>Analíticas de Videos</h1>
        </div>
        <div className="col-6">
          <div className="row mx-0">
            <div className="col-6 text-end">
              <input
                type="date"
                className="form-control ms-2"
                value={fechaInicio}
                onChange={(e) =>
                  setFechaInicio(moment(e.target.value).format("YYYY-MM-DD"))
                }
              />
            </div>
            <div className="col-6 text-end">
              <input
                type="date"
                className="form-control ms-2"
                value={fechaFin}
                onChange={(e) =>
                  setFechaFin(moment(e.target.value).format("YYYY-MM-DD"))
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container-fluid">
          <div className="card shadow-sm p-3  me-3 mb-3">
            <h4 className="border-bottom mb-3 pb-3">Reproducciones</h4>
            <div className="row bold">
              <div className="container-fluid">{renderChart()}</div>
            </div>
          </div>
          <div className="card shadow-sm p-3  me-3">
            <h4 className="border-bottom mb-3 pb-3">
              Top 10 Más Agregados a favoritos
            </h4>
            <div className="row bold">
              <div className="col-3">Titulo</div>
              <div className="col-3">Coach</div>
              <div className="col-3">Fecha</div>
              <div className="col-3">Favoritos</div>
            </div>
            {renderFavorites()}
          </div>
          <div className="card shadow-sm p-3  me-3 mt-4">
            <h4 className="border-bottom mb-3 pb-3">Top 10 Más Reproducidos</h4>
            <div className="row bold">
              <div className="col-3">Titulo</div>
              <div className="col-2">Coach</div>
              <div className="col-2">Fecha</div>
              <div className="col-2">Totales</div>
              <div className="col-2">Únicas</div>
            </div>
            {renderPlaybacks()}
          </div>
          <div className="card shadow-sm p-3  me-3 mt-4">
            <h4 className="border-bottom mb-3 pb-3">Últimas 10 Clases</h4>
            <div className="row bold">
              <div className="col-3">Titulo</div>
              <div className="col-2">Coach</div>
              <div className="col-2">Fecha</div>
              <div className="col-2">Totales</div>
              <div className="col-2">Únicas</div>
            </div>
            {renderUltimas()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaliticasVideos;
