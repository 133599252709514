import React from "react";
import moment from "moment";

const MonthScheduleAdminDay = ({ day, selectedDay, clases, setDay }) => {
  const renderButton = () => {
    if (clases > 0) {
      return (
        <button onClick={setDay} className="btn btn-round btn-primary">
          {clases}
        </button>
      );
    }
  };

  const renderArrow = () => {
    if (day === selectedDay) {
      return <div className="top-arrow"></div>;
    }
  };

  return (
    <div
      className={`px-0 text-center my-4 schedule-day border-bottom bg-light ${
        day.details.length === 0 ? "hide-mobile" : ""
      }`}
    >
      <div className="row mx-0">
        <div className="container-fluid text-center">
          {moment(day.date).utc().format("DD")}
        </div>
      </div>
      <div className="row mx-0 my-3 bg-dark text-white">
        <div className="container-fluid text-center">
          {moment(day.date).utc().format("dd")}
        </div>
      </div>
      <div className="class-container px-2">{renderButton()}</div>
      {renderArrow()}
    </div>
  );
};

export default MonthScheduleAdminDay;
