import React, { useEffect } from "react";
import ImageInput from "../common/ImageInput";
import { Link } from "@reach/router";
import moment from "moment";

const VideoForm = ({
  tags,
  video,
  programs,
  modifier,
  postVideo,
  class_types,
  video_types,
  instructors,
  spinner,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...video };
    postVideo(data);
  };

  useEffect(() => {
    if (video !== null) {
      if (Array.isArray(class_types)) {
        if (
          video.class_type_id === "" ||
          video.class_type_id === null ||
          !video.class_type_id
        ) {
          if (class_types.length > 0) {
            modifier("class_type_id", class_types[0].class_type_id);
          }
        }
      }
      if (Array.isArray(video_types)) {
        if (
          video.video_type_id === "" ||
          video.video_type_id === null ||
          !video.video_type_id
        ) {
          if (video_types.length > 0) {
            modifier("video_type_id", video_types[0].video_type_id);
          }
        }
      }
      if (Array.isArray(instructors)) {
        if (
          video.instructor_id === "" ||
          video.instructor_id === null ||
          !video.instructor_id
        ) {
          if (instructors.length > 0) {
            modifier("instructor_id", instructors[0].instructor_id);
          }
        }
      }
      if (Array.isArray(tags)) {
        if (video.tag_id === "" || video.tag_id === null || !video.tag_id) {
          if (tags.length > 0) {
            modifier("tag_id", tags[0].tag_id);
          }
        }
      }
      if (Array.isArray(programs)) {
        if (
          video.program_id === "" ||
          video.program_id === null ||
          !video.program_id
        ) {
          if (programs.length > 0) {
            modifier("program_id", programs[0].program_id);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_types, video_types, instructors, programs, tags, video]);

  const handleUrlModifier = (e) => {
    let { value } = e.target;
    value = String(value);
    if (value.includes("vimeo")) {
      let current = value.split("https://vimeo.com/")[1];
      if (current) {
        current = String(current);
        value = current.replace("/", "?h=");
        value = value.replace("?share=copy", "");
      }
    }
    modifier("url", value);
  };

  const renderinstructors = () => {
    if (instructors && instructors !== null) {
      return instructors.map((instructor) => (
        <option key={instructor.instructor_id} value={instructor.instructor_id}>
          {instructor.name} {instructor.last_name}
        </option>
      ));
    }
  };

  const renderCoachForm = () => {
    return (
      <select
        className="form-control mb-3"
        onChange={(e) => modifier("instructor_id", e.target.value)}
        value={video.instructor_id}
      >
        {renderinstructors()}
      </select>
    );
  };

  const renderClassTypes = () => {
    if (class_types && class_types !== null) {
      return class_types.map((class_type) => (
        <option key={class_type.class_type_id} value={class_type.class_type_id}>
          {class_type.name}
        </option>
      ));
    }
  };

  const renderVideoTypes = () => {
    if (video_types && video_types !== null) {
      return video_types.map((video_type) => (
        <option key={video_type.class_type_id} value={video_type.video_type_id}>
          {video_type.name}
        </option>
      ));
    }
  };

  const renderForm = () => {
    if (video && video !== null) {
      const {
        name,
        description,
        url,
        video_type_id,
        class_type_id,
        available_at,
      } = video;
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => modifier("name", e.target.value)}
          />
          <label>Descripcion</label>
          <input
            type="text"
            className="form-control mb-3"
            value={description !== null ? description : ""}
            onChange={(e) => modifier("description", e.target.value)}
          />
          <label>URL de Video</label>
          <input
            type="text"
            className="form-control mb-3"
            value={url !== null ? url : ""}
            onChange={handleUrlModifier}
          />
          <ImageInput prop="picture" value={video.file} modifier={modifier} />
          <div className="container-fluid px-0">
            <label>Tipo de Clase</label>
            <select
              className="form-control mb-3"
              onChange={(e) => {
                modifier("class_type_id", e.target.value);
              }}
              value={class_type_id}
            >
              {renderClassTypes()}
            </select>
          </div>
          <div className="container-fluid px-0">
            <label>Tipo de Video</label>
            <select
              className="form-control mb-3"
              onChange={(e) => {
                modifier("video_type_id", e.target.value);
              }}
              value={video_type_id}
            >
              {renderVideoTypes()}
            </select>
          </div>
          <div className="container-fluid px-0">
            <label>Coach</label>
            {renderCoachForm()}
          </div>
          <div className="container-fluid px-0"></div>
          <div className="container-fluid px-0">
            <label>Disponible el</label>
            <input
              type="date"
              className="form-control mb-3"
              value={moment(available_at).utc().format("YYYY-MM-DD")}
              onChange={(e) => modifier("available_at", e.target.value)}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={spinner}
              >
                {spinner ? <div className="spinner-border"></div> : "Guardar"}
              </button>
            </div>
            <div className="col-6 text-right">
              <Link
                to="/myadmin/videos"
                className="btn btn-link text-secondary"
              >
                Cancelar
              </Link>
            </div>
          </div>
        </form>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default VideoForm;
