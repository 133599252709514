import React, { useContext, useEffect, useState } from "react";
import { LocationsContext } from "../../context/LocationsContext";
import { getValue } from "../../utils";
import TimezoneSelect from "react-timezone-select";

const LocationForm = ({ modifier, postLocation, handleCancel }) => {
  const { location } = useContext(LocationsContext);

  const validateTimeZone = () => {
    if(location?.time_zone && location.time_zone?.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validateTimeZone()) {
      postLocation(location);
    }
  };

  const renderForm = () => {
    if (location && location !== null) {
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(location, "name")}
            onChange={(e) => modifier("name", e.target.value)}
          />
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(location, "description")}
            onChange={(e) => modifier("description", e.target.value)}
          />
          <label>Dirección</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(location, "address")}
            onChange={(e) => modifier("address", e.target.value)}
          />
          <div className="row mb-3">
            <div className="col-6">
              <label>Ciudad</label>
              <input
                type="text"
                className="form-control"
                value={getValue(location, "city")}
                onChange={(e) => modifier("city", e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>País</label>
              <input
                type="text"
                className="form-control"
                value={getValue(location, "country")}
                onChange={(e) => modifier("country", e.target.value)}
              />
            </div>
          </div>
          
          <label>Zona Horaria</label>
          <label className="small">La Zona Horaria seleccionada será utilizada para mostrar la hora exacta de las clases creadas con esta ubicacion.</label>
          <TimezoneSelect
            required={true}
            className={`px-0 mb-3 `}
            value={getValue(location, "time_zone")}
            onChange={(data) => {
              modifier('time_zone', data.value);
            }}
          />

          <div className="row">
            <div className="col-6">
              <button
                type="button"
                onClick={handleCancel}
                className="btn w-100 text-muted"
              >
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button type="submit" className="btn w-100 btn-primary">
                Guardar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default LocationForm;
