import { Link } from "@reach/router";
import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getValue } from "../../utils";

const AdminTabs = () => {
  const appconfig = useContext(AppConfigContext);
  return (
    <ul className="side-menu-list">
      {getValue(appconfig, "testimonials_enabled", "boolean") && <li className="nav-item text-item">
        <Link to="/myadmin/testimonios" className="nav-link">
          Testimonios
        </Link>
      </li>}
      <li className="nav-item text-item">
        <Link to="/myadmin/ajustes" className="nav-link">
          Ajustes
        </Link>
      </li>
    </ul>
  );
};

export default AdminTabs;
