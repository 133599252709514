import React from "react";
import AsistenteRow from "../customers/AsistenteRow";

const AsistentesTable = ({
  single_class_id,
  handleCallback,
  confirmCancel,
  postPayment,
  postAttend,
  asistentes,
  postGear,
  user,
  clase
}) => {
  const renderAsistentes = () => {
    if (Array.isArray(asistentes)) {
      if (asistentes.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay clientes registradas.</td>
          </tr>
        );
      }
      return asistentes.map((customer) => (
        <AsistenteRow
          user={user}
          key={customer.class_reservation_id}
          asistente={customer}
          postAttend={postAttend}
          postPayment={postPayment}
          confirmCancel={confirmCancel}
          handleCallback={handleCallback}
          single_class_id={single_class_id}
          clase={clase}
        />
      ));
    }
  };

  const renderCustomerContact = () => {
    if (["manager", "admin", "super_admin"].includes(user.role)) {
      return [
        <td key="instagram">Instagram</td>,
        <td key="email">Correo</td>,
        <td key="phone">Teléfono</td>,
      ];
    }
  };

  return (
    <div className="table-responsive mt-3">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>Nombre</td>
            <td>Etiquetas</td>
            {renderCustomerContact()}
            <td>Spot</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderAsistentes()}</tbody>
      </table>
    </div>
  );
};

export default AsistentesTable;
