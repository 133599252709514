import React, { useEffect, useState } from "react";
import moment from "moment";

const SubscriptionOptions = ({ class_package, modifier }) => {
  const [freeTrialProp, setFreeTrialProp] = useState("free_trial_length");

  const {
    free_trial_end,
    // expiration_days,
    free_trial_length,
    subscription_period,
    subscription_interval,
  } = class_package;

  useEffect(() => {
    if (free_trial_end !== null) {
      setFreeTrialProp("free_trial_end");
      if (free_trial_end === "") {
        modifier("free_trial_end", null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const free_trial_value =
    freeTrialProp === "free_trial_end"
      ? moment(free_trial_end).utc().format("YYYY-MM-DD")
      : free_trial_length;
  return (
    <div>
      <div className="row align-items-center mb-2">
        <label>Cargo a Tarjeta Cada</label>
        <div className="col-12 col-md-2 mb-3">
          <input
            type="number"
            className="form-control"
            value={subscription_interval}
            onChange={(e) => modifier("subscription_interval", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-10 mb-3">
          <select
            className="form-control"
            value={subscription_period}
            onChange={(e) => modifier("subscription_period", e.target.value)}
          >
            <option value="day">Día(s)</option>
            <option value="month">Mes(es)</option>
            <option value="year">Año(s)</option>
          </select>
        </div>
      </div>
      <div className="row align-items-center mb-2">
        <label>Periodo de Prueba Gratis</label>
        <div className="col-12 col-md-2 mb-3">
          <select
            value={freeTrialProp}
            className="form-control"
            onChange={(e) => setFreeTrialProp(e.target.value)}
          >
            <option value="free_trial_length">Días</option>
            <option value="free_trial_end">Fecha</option>
          </select>
        </div>
        <div className="col-12 col-md-10 mb-3">
          <input
            type={freeTrialProp === "free_trial_length" ? "number" : "date"}
            className="form-control"
            placeholder="Dejar en blanco para desactivar"
            value={free_trial_value !== null ? free_trial_value : ""}
            onChange={(e) => modifier(freeTrialProp, e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionOptions;
