import { useContext, useEffect, useState } from "react";
import HomeSectionsForm from "./HomeSectionsForm";
import HomeSectionResources from "./HomeSectionsResources";

const HomeSection = () => {
 
  useEffect(() => {
  }, []);


  return (
    <div className="container">
      <HomeSectionsForm/>
      <HomeSectionResources/>
    </div>
  );
};

export default HomeSection;
